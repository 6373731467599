import 'lazysizes'
import * as React from 'react'
import { SOCIAL_MEDIA_LINKS } from '../../constants'
import facebookIcon from './../../assets/images/facebook.svg'
import instagramIcon from './../../assets/images/instagram.svg'
import pinterestIcon from './../../assets/images/pinterest.svg'
import tiktokIcon from './../../assets/images/tiktok.svg'
import twitterIcon from './../../assets/images/twitter.svg'
import { styles } from './styles'

interface SocialMediaProps {
  size: 'sm' | 'md'
}

const sizeMap: Record<SocialMediaProps['size'], number> = {
  sm: 20,
  md: 25,
}

const CHRSocialMedia = ({ size = 'md' }: SocialMediaProps) => {
  const classes = styles()

  const iconSize = sizeMap[size]

  const itemSizeMap = {
    sm: classes.socialListItemSm,
    md: classes.socialListItemMd,
  }

  return (
    <ul className={classes.socialMediaList}>
      <li className={itemSizeMap[size]}>
        <a
          href={SOCIAL_MEDIA_LINKS.instargram}
          target="_blank"
          className={classes.socialIcon}
          rel="noreferrer"
        >
          <img
            src={instagramIcon}
            alt="instagram"
            width={iconSize}
            height={iconSize}
          />
        </a>
      </li>
      <li className={itemSizeMap[size]}>
        <a
          href={SOCIAL_MEDIA_LINKS.tiktok}
          target="_blank"
          className={classes.socialIcon}
          rel="noreferrer"
        >
          <img
            src={tiktokIcon}
            className="lazyload"
            alt="tiktok"
            width={iconSize}
            height={iconSize}
          />
        </a>
      </li>
      <li className={itemSizeMap[size]}>
        <a
          href={SOCIAL_MEDIA_LINKS.pinterest}
          target="_blank"
          className={classes.socialIcon}
          rel="noreferrer"
        >
          <img
            src={pinterestIcon}
            alt="pinterest"
            width={iconSize}
            height={iconSize}
          />
        </a>
      </li>
      <li className={itemSizeMap[size]}>
        <a
          href={SOCIAL_MEDIA_LINKS.facebook}
          target="_blank"
          className={classes.socialIcon}
          rel="noreferrer"
        >
          <img
            src={facebookIcon}
            alt="facebook"
            width={iconSize}
            height={iconSize}
          />
        </a>
      </li>
      <li className={itemSizeMap[size]}>
        <a
          href={SOCIAL_MEDIA_LINKS.twitter}
          target="_blank"
          className={classes.socialIcon}
          rel="noreferrer"
        >
          <img
            src={twitterIcon}
            alt="twitter"
            width={iconSize}
            height={iconSize}
          />
        </a>
      </li>
    </ul>
  )
}

export default CHRSocialMedia
